<template>
  <div class="">
    <div class="form-row">
      <DetailOrderItemInputInventory
        label="สินค้า"
        ref="dropdownInventory"
        placeholder="สินค้า..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        @change="selectedConfig = $event"
        v-model="formData.docConfigId"
        :validations="[
          {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
        ]">
      </DetailOrderItemInputInventory>

      <sgv-input-number
        label="จำนวน"
        class="col-6"
        placeholder="จำนวน"
        v-model="formData.qty"
        :validations="[
          {text: 'required!', value: $v.formData.qty.$dirty && !$v.formData.qty.required},
          {text: 'จำนวนมากกว่า 0', value: $v.formData.qty.$dirty && !$v.formData.qty.minValue}
        ]"
        @keyup.enter.native="createOrder">
      </sgv-input-number>

      <sgv-input-text
        label="stockId"
        class="col-6"
        placeholder="stockId"
        v-model="formData.stockId"
        :validations="[
          {text: 'required!', value: $v.formData.stockId.$dirty && $v.formData.stockId.$error}
        ]"
        @keyup.enter.native="createOrder">
      </sgv-input-text>

      <template v-for="(transferItem, i) in formData.transferItems">
        <sgv-input-number
          disabled
          v-if="transferItem.disabled"
          :key="transferItem.id"
          :precision="2"
          :label="
            `${transferItem.inventory.code}
            (${transferItem.inventory.name})
            [จำนวน] * ${transferItem.qty}
            ${transferItem.inventory.unit.name}`
          "
          class="col-6"
          :value="transferItem.qty * Number(formData.qty || 1)"
          :validations="[
            {text: 'required!', value: $v.formData.transferItems.$each[i].qty.$dirty && !$v.formData.transferItems.$each[i].qty.required},
            {text: 'จำนวนมากกว่า 0', value: $v.formData.transferItems.$each[i].qty.$dirty && !$v.formData.transferItems.$each[i].qty.minValue}
          ]">
        </sgv-input-number>

        <sgv-input-number
          v-else
          :key="transferItem.id"
          :precision="2"
          :label="
            `${transferItem.inventory.code}
            (${transferItem.inventory.name})
            [จำนวน]
            ${transferItem.inventory.unit.name}`
          "
          class="col-6"
          v-model="transferItem.qty"
          @keyup.enter.native="createOrder"
          :validations="[
            {text: 'required!', value: $v.formData.transferItems.$each[i].qty.$dirty && !$v.formData.transferItems.$each[i].qty.required},
            {text: 'จำนวนมากกว่า 0', value: $v.formData.transferItems.$each[i].qty.$dirty && !$v.formData.transferItems.$each[i].qty.minValue}
          ]">
        </sgv-input-number>
      </template>
    </div>

    <div class="form-group">
      <button class="btn btn-success" @click="createOrder">
        เพิ่ม
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderItemInputInventory from './DetailOrderItemInputInventory.vue'
import { CREATE_ORDER } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formType: 'item',
      formData: {
        docConfigId: null,
        qty: 0,
        stockId: '',
        transferItems: []
      },
      selectedConfig: null
    }
  },
  validations () {
    const formData = {
      docConfigId: {required},
      qty: {
        required,
        minValue: (value) => value > 0
      },
      stockId: {required}
    }
    if (this.formData.transferItems.length > 0) {
      formData.transferItems = {
        $each: {qty: {required, minValue: (value) => value > 0}}
      }
    }
    return {formData}
  },
  methods: {
    serializeInput (input) {
      const formData = {
        docConfigId: input.docConfigId,
        qty: input.qty,
        stockId: input.stockId.split(' ').map(v => +v),
        transferItems: input.transferItems.map(v => {
          return {
            docConfigId: v.docConfigId,
            qty: v.qty
          }
        })
      }

      return formData
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(res => {
        this.$emit('orderCreated', res.data.createOrder)
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$refs.dropdownInventory.setFocus()
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.qty = 0
      this.formData.transferItems = this.getTransferItem()
      this.$v.formData.$reset()
    },
    getTransferItem () {
      if (!this.selectedConfig) return []
      return this.selectedConfig.children
        .filter(v => v.type === 'transferItem')
        .map(v => {
          return {
            docConfigId: v.id,
            type: v.type,
            inventory: v.inventory,
            account: v.account,
            qty: v.qty,
            disabled: v.qty > 0
          }
        })
    }
  },
  watch: {
    'formData.docConfigId': {
      handler () {
        this.formData.qty = 0
        this.formData.transferItems = this.getTransferItem()
        this.$v.formData.$reset()
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderItemInputInventory
  }
}
</script>

<style lang="css" scoped>
</style>
